import React from 'react';
import Layout from '../layout';
import { Link } from 'gatsby';

if (typeof window !== `undefined`) {
  require('bootstrap/dist/css/bootstrap.min.css');
}

export default function Obfuscated() {
  return (
    <Layout>
      <div className="container site-content">
        <p>
          Sorry, looks like you got lost. <Link to="/"> Return to home </Link>
        </p>
      </div>
    </Layout>
  );
}
